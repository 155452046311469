import React from 'react'

import classNames from 'classnames'
import DefaultLayout from '../../layout/default'

import CtaForm from '../../pagesSections/index/CtaForm'

import pageStyles from '../../styles/_V2/pagesStyles/styles.module.scss'
import styles from '../styles/_V2/docs.module.scss'
import AdaptiveLink from '../../components/AdaptiveLink'
import {pagesLinks} from '../../pages-data/common/links'

export default function PagePrivacyPolicy() {
    const metaTags = [
        // {
        //   name: 'keywords',
        //   content: 'Автоматизация ресторана, автоматизация кафе, автоматизация бара, автоматизация столовой, ipad pos, касса на ipad, калькуляция, расчет себестоимости, технологическая карта, российская система автоматизации',
        // },
        {
            name: 'description',
            content: 'Лицензионный договор является офертой ООО «Квик Ресто» — система автоматизации предприятий общественного питания Quick Resto — программа для полноценного складского учёта в общепите онлайн, удобный кассовый терминал на планшете, CRM и настраиваемая система отчетов.',
        },
        {
            property: 'og:url',
            content: 'https://quickresto.ru/licenseAgreement/annex2',
        },
        {
            property: 'og:title',
            content: 'Лицензионный договор на право использования системы автоматизации Quick Resto',
        },
        {
            property: 'og:description',
            content: 'Лицензионный договор является офертой ООО «Квик Ресто» — система автоматизации предприятий общественного питания Quick Resto — программа для полноценного складского учёта в общепите онлайн, удобный кассовый терминал на планшете, CRM и настраиваемая система отчетов.',
        },
        {
            property: 'og:type',
            content: 'website',
        },
    ]

    return (
        <DefaultLayout
            title="Приложение № 2"
            metaTags={metaTags}
            mainWrapperClassName={classNames(
                pageStyles.pageWrapper,
                pageStyles.pageGray,
                styles.docs,
            )}
            linkCanonical="https://quickresto.ru/licenseAgreement/annex2/"
            footerClassName={pageStyles.pageSection}
        >
            <section
                className={classNames(styles.docs__container, pageStyles.pageSection)}
            >
                <div className={styles.docs__content}>
                    <h1 className={styles.bigMb}>
                        Специальные условия Лицензии на Опциональную программу - «Брендированный клиентский сайт»
                        входящую в пакет Опциональных программ для ЭВМ «Лояльность.360»
                    </h1>

                    <div>
                        <p>
                            Приложение № 2
                            к Лицензионному договору на право использования
                            программы для ЭВМ «Система автоматизации предприятий
                            общественного питания «Quick Resto»» и иных
                            программных продуктов ООО «Квик Ресто»
                        </p>

                    </div>

                    <div>
                        <p/>

                        <p>1. Настоящее Приложение, как и Лицензионный договор, является
                            офертой <strong>ООО</strong><strong>&laquo;Квик Ресто&raquo;</strong>&nbsp;<br/> (ОГРН
                            5137746099046, ИНН&nbsp;7726734798, 125167 г. Москва, Ленинградский пр. 36, стр. 30, пом.
                            96), именуемого в дальнейшем Лицензиар, адресованной юридическому лицу или индивидуальному
                            предпринимателю, именуемому в дальнейшем &laquo;Лицензиат&raquo; заключить лицензионное
                            соглашение в соответствии с п. 2 ст. 437 Гражданского кодекса Российской Федерации.</p>
                        <p><br/>2. Под офертой согласно настоящему Приложению № 2 подразумевается предложение Лицензиара
                            адресованное, <u>имеющему лицензию на основную Программу Лицензиату</u>, приобрести за
                            дополнительное вознаграждение Пакет опциональных дополнительных программных функциональных
                            модулей &laquo;Лояльность 360&raquo; для Программы ЭВМ Лицензиара в частности входящий в
                            указанный пакет опциональный дополнительный программный функциональный
                            модуль <strong>&laquo;Брендированный клиентский сайт&raquo;</strong> на основании простой
                            (неисключительной) лицензии на результат интеллектуальной деятельности ООО &laquo;Квик
                            Ресто&raquo; &ndash; разработанный Лицензиаром дополнительный программный функциональный
                            модуль &laquo;Брендированный клиентский сайт&raquo; (далее &ndash; &laquo;Клиентский
                            сайт&raquo;, &laquo;Сайт&raquo;, Сервис &laquo;Брендированный клиентский сайт&raquo;),
                            который Лицензиар в соответствии с требованиями Лицензиата визуально адаптирует под его
                            деятельность и который предназначается для использования клиентами Лицензиата (потребителями
                            товаров и/или услуг Лицензиата).</p>
                        <p>Модуль &laquo;Брендированный клиентский сайт&raquo; позволяет Лицензиату на основе макета
                            встроенного в модуля, самостоятельно формировать (наполнять содержимым) и редактировать свой
                            сайт в сети интернет, в том числе создавать на своем сайте электронное меню предлагаемых
                            Лицензиатом блюд и напитков с указанием их названия, описания состава и стоимости,
                            отображать текущие акции в заведении с указанием описания, периода действия , а также
                            позволяет клиентам Лицензиата оформлять доставку блюд и напитков в соответствии с их
                            заказами, сформированными дистанционным способом на основании электронного меню на сайте
                            Лицензиата.</p>
                        <p>Простая (неисключительная) лицензия на результат интеллектуальной деятельности
                            ООО &laquo;Квик Ресто&raquo; &ndash; разработанный Лицензиаром дополнительный программный
                            функциональный модуль &laquo;Брендированный клиентский сайт&raquo; реализуется исключительно
                            в пакете опциональных дополнительных программных функциональных модулей &laquo;Лояльность
                            360&raquo; и исключительно пользователям, имеющим Лицензию на основную Программу
                            Лицензиара</p>
                        <p>Акцепт на указанную в п.2 настоящего Приложения №2 оферту возможен при одновременном
                            соблюдении 3 условий:</p>
                        <p>1) Лицензиат приобретающий Лицензию на Клиентский сайт в пакете опциональных дополнительных
                            программных функциональных модулей &laquo;Лояльность 360&raquo; должен иметь Лицензию на
                            основной программный продукт Лицензиара (Программу)</p>
                        <p>2) Лицензиат приобретает Лицензию на Клиентский сайт в Пакете опциональных дополнительных
                            программных функциональных модулей &laquo;Лояльность 360&raquo; со всеми входящими в
                            указанный Пакет опциональными дополнительными программными модулями.</p>
                        <p>3) Оплата вознаграждения за пакет опциональных дополнительных программных функциональных
                            модулей &laquo;Лояльность 360&raquo; по счету, выставленному Лицензиаром в соответствии с
                            Тарифным планом, выбранным Лицензиатом</p>
                        <p>При несоблюдении любого из вышеуказанных пунктов &ndash; оферта не считается акцептованной
                            Лицензиатом</p>
                        <p>2.1. Сервис &laquo;Брендированный клиентский сайт&raquo; является универсальным программным
                            продуктом и предоставляется пользователям (Лицензиатам) в том виде &laquo;как
                            есть&raquo;.</p>
                        <p>3. Лицензиар гарантирует Лицензиату, что он является законным правообладателем опционального
                            дополнительного программного функционального модуля для Программы ЭВМ Лицензиара
                            - &laquo;Брендированный клиентский сайт&raquo;, в силу создания такого модуля</p>
                        <p>4. Срок действия Лицензии на Пакет опциональных дополнительных программных функциональных
                            модулей &laquo;Лояльность 360&raquo; в частности входящий в указанный пакет опциональный
                            дополнительный программный функциональный модуль <strong>&laquo;Брендированный клиентский
                                сайт&raquo;</strong> и срок доступа к опциональному дополнительному программному
                            модулю<strong>&laquo;Брендированный клиентский сайт&raquo;</strong>входящейв опциональных
                            дополнительных программных функциональных модулей для ЭВМ &laquo;Лояльность 360&raquo;,
                            определяются в соответствии с Разделом 2 и Разделом 4 Лицензионного договора на право
                            использования программы для ЭВМ &laquo;Система автоматизации предприятий общественного
                            питания &laquo;Quick Resto&raquo; и иных программных продуктов ООО &laquo;Квик Ресто&raquo;.
                        </p>
                        <p>По итогам периода использования прав на Пакет опциональных дополнительных программных
                            функциональных модулей &laquo;Лояльность 360&raquo; в частности входящий в указанный пакет
                            Опциональный дополнительный программный функциональный модуль <strong>&laquo;Брендированный
                                клиентский сайт&raquo;</strong> Лицензиар направляет на электронную почту, указанную
                            Лицензиатом, акт исполнения обязательств по настоящему Договору. При отсутствии у Лицензиата
                            в течение 3 (трех) календарных дней с момента направления ему данного акта и отсутствия
                            мотивированных возражений, данный акт считается согласованным и подписанным Лицензиатом, а
                            услуги принятыми без претензий к качеству и срокам.</p>
                        <p><br/> 5. Продление Лицензии на Пакет опциональных дополнительных программных функциональных
                            модулей &laquo;Лояльность 360&raquo; в частности входящий в указанный пакет опциональный
                            дополнительный программный функциональный модуль &laquo;<strong>Брендированный клиентский
                                сайт&raquo;</strong> и оплата вознаграждения за предоставление Лицензии на Пакет
                            опциональных дополнительных программных функциональных модулей &laquo;Лояльность
                            360&raquo; в частности входящий в указанный пакет опциональный дополнительный программный
                            функциональный модуль &laquo;<strong>Брендированное мобильное приложение &laquo;White
                                label&raquo; </strong>осуществляется на условиях определяются в соответствии с Разделом
                            2 и Разделом 4 Лицензионного договора на право использования программы для
                            ЭВМ &laquo;Система автоматизации предприятий общественного питания &laquo;Quick
                            Resto&raquo; и иных программных продуктов ООО &laquo;Квик Ресто&raquo;</p>
                        <p>6. Сервис &laquo;Клиентский сайт&raquo; размещается в интернет на домене Лицензиара по
                            адресу: <br/> в формате <u><a
                                href="https://xn--_-7sbabhdoedbneki5dej93a.menuquickresto.ru/">https://название_заведения.menuquickresto.ru/</a></u>,
                            где название заведения предоставляется клиентом, либо привязывается к домену клиента
                            (названию облака), при оплате соответствующей услуги.</p>
                        <p><br/>7. Во всем ином, не предусмотренном настоящим Приложением № 2, применяются положения
                            Лицензионного договора, а также положения действующего законодательства Российской
                            Федерации.</p>
                        <p><br/>8. Краткое описание Сервиса &laquo;Клиентский сайт&raquo; и возможностей опционального
                            дополнительного программного функционального модуля, а также его преимуществ содержится по
                            следующей интернет ссылке: <u><a
                                href="https://quickresto.ru/emenu/">https://quickresto.ru/emenu/</a></u> и <u><a
                                href="https://quickresto.ru/wl/">https://quickresto.ru/wl/</a></u></p>
                        <p>9. Лицензиат подтверждает, что он:</p>
                        <p>&middot;&nbsp;перед заключением настоящего Приложения № 2 в полном объеме ознакомлен с
                            функционалом (возможностями) Сервиса &laquo;Клиентский сайт&raquo;;</p>
                        <p>- перед заключением настоящего Приложения № 2 в полном объеме ознакомлен с текстом
                            Лицензионного договора на право использования программы для ЭВМ &laquo;Система автоматизации
                            предприятий общественного питания &laquo;Quick Resto&raquo;&raquo; и иных программных
                            продуктов ООО &laquo;Квик Ресто&raquo;, в котором содержатся все основные условия
                            предоставления лицензий на программные продукты ООО &laquo;Квик Ресто&raquo; и который
                            содержится по следующей интернет ссылке: <u><a
                                href="https://quickresto.ru/licenseAgreement/">https://quickresto.ru/licenseAgreement/</a></u>;
                        </p>
                        <p>- соглашается с тем, что:</p>
                        <p>С 01.09.2024 г. новые Лицензиаты (новые клиенты) не имеют возможность приобрести лицензию на
                            пакет Опциональных дополнительных программных функциональных модулей для Программы ЭВМ
                            Лицензиара &laquo;Лояльность 360&raquo; без приобретения Программы.</p>
                        <p>Действующие Лицензиаты, имеющие оплаченные/приобретенные Лицензии на опциональные
                            дополнительные программные функциональные модули на 01.09.2024 г. (до введения
                            пакета &laquo;Лояльность 360&raquo;) пользуются лицензиями до момента окончания своих
                            действующих лицензий на опциональные дополнительные программные функциональные модули, после
                            окончания срока таких лицензий возможность приобрести/продлить опциональные дополнительные
                            программные функциональные модули без покупки основной Программы не допускается.</p>
                        <p>Уведомлен об отсутствии, в связи с изменением тарифной политики, возможности приобрести
                            Сервис &laquo;Клиентский сайт&raquo;отдельно отопциональных дополнительных программных
                            функциональных модулей из Пакета &laquo;Лояльность 360&raquo;,</p>
                        <p>- Предупрежден о том, что без приобретения Лицензии на основную Программу/в случае окончания
                            лицензии на основную Программу, опциональные дополнительные программные функциональные
                            модули будут работать в ограниченном режиме, с ограниченным функционалом/нестабильно и
                            Лицензиар не несет ответственности за такие действия, так как это не зависит от действий
                            Лицензиара, а связано с техническими особенностями Программы и Опциональных дополнительных
                            программных функциональных модулей</p>
                        <p>- осознает, что Программа является основным продуктом Лицензиара, без которого использование
                            любого опционального дополнительного программного функционального модуля из
                            Пакета &laquo;Лояльность 360&raquo; в полном объеме будет функционально ограничено, в виду
                            того, что модули являются вторичными (дополнительными) к Программе и внедряются в основную
                            Программу, позволяя расширить ее функционал, а именно в панель управления Программой. В
                            отсутствие приобретенной лицензии на основную Программу, основные разделы в панели не будут
                            доступны/будут работать нестабильно, что повлияет на работу опциональных дополнительных
                            программных функциональных модулей.</p>
                        <p>-&nbsp;осознаёт, что Сервис &laquo;Клиентский сайт&raquo; является универсальным программным
                            продуктом и предоставляется пользователям Лицензиатам в том виде &laquo;как есть&raquo;, за
                            исключением визуального адаптирования самим Лицензиатом под деятельность Лицензиата, без
                            изменения функционала Мобильного приложения, иные адаптации оцениваются человеко-часами и
                            оплачиваются дополнительно</p>
                        <p>- осознает, что при неуплате продления Лицензии на пакет опциональных дополнительных
                            программных функциональных модулей &laquo;Лояльность 360&raquo; куда входит
                            Сервис &laquo;Клиентский сайт&raquo;, Лицензиар вправе отключить, а затем удалить
                            Сервис &laquo;Клиентский сайт&raquo; и отключить функционал настройки в облачном бэк-офисе
                            Лицензиата;</p>
                        <p>- заключает настоящее Приложение № 2, так как убедился в том, что Сервис &laquo;Клиентский
                            сайт&raquo; в полной мере соответствует потребностям и ожиданиям Лицензиата.</p>
                        <p>- Не несет расходы на хранение данных Лицензиата, а также техническую поддержку,
                            исключительно на период действия оплаченной Лицензии.</p>
                        <p>&middot; осознаёт, что, используя функциональные возможности Сервиса &laquo;Клиентский
                            сайт&raquo;, Лицензиат самостоятельно формирует (наполняет содержимым) и редактирует свой
                            сайт в сети интернет;</p>
                    </div>
                </div>
            </section>

            <CtaForm
                isFry={false}
                className={pageStyles.pageSection}
                isWhiteBackground
            />
        </DefaultLayout>
    )
}
